import styled from "styled-components";
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  MintSection,
} from "../components";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import { car1, car2, car3, car4miura, car5, car5Mob } from "../helpers/images";

const MiuraPage = () => {
  const screens = useWindowMediaQuery();
  return (
    <MiuraPageStyled screens={screens}>
      <Section1 title={"PhantomMiura"} img={car1} />
      <Section2
        text={
          "L'iconica Lamborghini Miura non ha bisogno di presentazioni: è un'auto che ha rivoluzionato la storia, diventando il simbolo del design italiano nel mondo. PhantomMiura è il mito della Miura visto attraverso il futuro. Digitale, ma sempre da collezione, Phantom Miura rigenera le linee immortali del modello originario in una viva stilizzazione che, attraverso una luce intermittente, ne esalta la fiamma di design, con aggressività di una fiera a riposo."
        }
        img={car2}
      />
      <Section3
        text={
          "The iconic Lamborghini Miura needs no introduction: it is a car that has revolutionized history, becoming the symbol of Italian design in the world. PhantomMiura is the myth of the Miura, seen through the future. Digital, but always collectible, Phantom Miura regenerates the immortal lines of the original model in a lively stylization that, through an intermittent light, enhances the design flame with the aggressiveness of a restful beast."
        }
        img={car3}
      />
      <Section4 img={car4miura} />
      <Section5 />
      <MintSection img={car5} mobImg={car5Mob} />
    </MiuraPageStyled>
  );
};

const MiuraPageStyled = styled.div`
  .img {
    overflow: hidden;
    width: calc(100vw);
    max-width: ${(props) => (props.screens.sm ? "" : "100%")};
  }
`;

export default MiuraPage;
