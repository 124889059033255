import ethereumLogoUrl from "../assets/images/ethereum-logo.png";
import polygonMaticLogo from "../assets/svg/polygon-matic-logo.svg";
import { SupportedChainId } from "./chains";

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === "undefined") {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`
  );
}

export const NETWORK_URLS = {
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
};

export const CHAIN_INFO = {
  [SupportedChainId.POLYGON]: {
    bridge: "https://wallet.polygon.technology/bridge",
    explorer: "https://polygonscan.com/",
    label: "Polygon",
    logoUrl: polygonMaticLogo,
    addNetworkInfo: {
      nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
      rpcUrl: NETWORK_URLS[SupportedChainId.POLYGON],
    },
  },
  [SupportedChainId.RINKEBY]: {
    explorer: "https://rinkeby.etherscan.io/",
    label: "Rinkeby",
    logoUrl: ethereumLogoUrl,
    addNetworkInfo: {
      nativeCurrency: { name: "Rinkeby Ether", symbol: "rETH", decimals: 18 },
      rpcUrl: NETWORK_URLS[SupportedChainId.RINKEBY],
    },
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    bridge: "https://wallet.polygon.technology/bridge",
    explorer: "https://mumbai.polygonscan.com/",
    label: "Polygon Mumbai",
    logoUrl: polygonMaticLogo,
    addNetworkInfo: {
      nativeCurrency: {
        name: "Polygon Mumbai Matic",
        symbol: "mMATIC",
        decimals: 18,
      },
      rpcUrl: "https://rpc-endpoints.superfluid.dev/mumbai",
    },
  },
};
