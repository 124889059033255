import styled from "styled-components";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section3 = ({ text, img }) => {
  const screens = useWindowMediaQuery();
  return (
    <Section3Styled screens={screens}>
      <div className="d-flex align-items-center flex-wrap">
        <Col className="text mt-5 mb-4">{text}</Col>
        <Col className="text-align-right">
          <img src={img} className="img" alt="bike3" />
        </Col>
      </div>
    </Section3Styled>
  );
};

Section3.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
};

const Section3Styled = styled.div`
  .text {
    margin: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    color: ${colors.textColor};
    margin: ${(props) => (props.screens.sm ? "0 25px" : "0 100px")};
  }
`;

export default Section3;
