import { desktopPreloader, mobilePreloader } from "../helpers/images";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const PreLoader = () => {
  const screens = useWindowMediaQuery();
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: screens.sm ? "70px" : "110px",
      }}
    >
      <img
        src={screens.sm ? mobilePreloader : desktopPreloader}
        alt="Preloader"
        style={screens.sm ? { width: "120px" } : {}}
      />
    </div>
  );
};

export default PreLoader;
