import styled from "styled-components";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { colors } from "../helpers/colors";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section2 = ({ text, img }) => {
  const screens = useWindowMediaQuery();
  const textToShow = <p>{text}</p>;

  const imgToShow = <img src={img} className="img" alt="bike2" />;
  return (
    <Section2Styled screens={screens}>
      <div className="d-flex align-items-center flex-wrap">
        {screens.sm ? (
          <>
            <Col className="mt-5 mb-4 text">{textToShow}</Col>
            <Col className="text-align-right">{imgToShow}</Col>
          </>
        ) : (
          <>
            <Col className="text-align-right">{imgToShow}</Col>
            <Col className="text">{textToShow}</Col>
          </>
        )}
      </div>
    </Section2Styled>
  );
};

Section2.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
};

const Section2Styled = styled.div`
  .text {
    margin: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: ${colors.textColor};
    margin: ${(props) => (props.screens.sm ? "0 25px" : "0 100px")};
  }
`;

export default Section2;
