import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InfoIcon } from "../helpers/images";
import { colors } from "../helpers/colors";
import ConnectWalletBtn from "./ConnectWalletBtn";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import { ClaimState, useClaimCallback } from "../hooks/useClaimCallback";
import { MintState, useMintCallback } from "../hooks/useMintCallback";
import { useWeb3React } from "@web3-react/core";
import { ApprovalState, useApproveCallback } from "../hooks/useApproveCallback";
import TransactionConfirmationModal from "./TransactionConfirmationModal/TransactionConfirmationModal";
import {
  useNotActiveAndError,
  useTriedEager,
} from "../state/web3Manager/hooks";

const MintSection = ({ img, mobImg }) => {
  const screens = useWindowMediaQuery();
  // tried eager and notactiveanderror hooks from redux
  const triedEager = useTriedEager();
  const notActiveAndError = useNotActiveAndError();

  const [noOfTokens, setNoOfTokens] = useState(1);
  // single state for modal and loading
  const [{ showConfirm, attemptingTxn, txHash, errorMessage }, setModalState] =
    useState({
      showConfirm: false,
      attemptingTxn: false,
      txHash: undefined,
      errorMessage: undefined,
    });
  const { active } = useWeb3React();
  const [claimState, claimCallback] = useClaimCallback();
  const [mintState, mintCallback] = useMintCallback(noOfTokens);
  const [approvalState, approveCallback] = useApproveCallback(noOfTokens);

  const btnText = useMemo(() => {
    if (!active) return "Connect your wallet to buy";

    if (
      claimState === ClaimState.INSUFFICIENT_ETH ||
      mintState === MintState.INSUFFICIENT_ETH
    )
      return "Insufficient ETH Balance";

    if (mintState === MintState.INSUFFICIENT_USDT)
      return "Insufficient USDT Balance";

    if (claimState === ClaimState.ELIGIBLE) return "Click here to Claim";

    return "Click here to Buy";
  }, [active, claimState, mintState]);

  const btnDisable = useMemo(() => {
    if (noOfTokens === 0) return true;
    if (!active) return true;
    if (
      claimState === ClaimState.ELIGIBLE &&
      claimState === ClaimState.INSUFFICIENT_ETH
    )
      return true;
    if (
      (mintState === MintState.ELIGIBLE || claimState === ClaimState.CLAIMED) &&
      (mintState === MintState.INSUFFICIENT_ETH ||
        mintState === MintState.INSUFFICIENT_USDT)
    )
      return true;

    // if (claimState === ClaimState.UNKNOWN || mintState === MintState.UNKNOWN)
    //   return true;

    return false;
  }, [noOfTokens, active, mintState, claimState]);

  const tokenPrice = useMemo(() => {
    switch (noOfTokens) {
      case 1:
        return 200;
      case 2:
        return 400;
      case 3:
        return 600;

      default:
        return 0;
    }
  }, [noOfTokens]);

  const infoText = useMemo(() => {
    if (claimState === ClaimState.ELIGIBLE)
      return "You are eligible to claim 1 NFT";

    if (
      claimState === ClaimState.INSUFFICIENT_ETH ||
      mintState === MintState.INSUFFICIENT_ETH ||
      mintState === MintState.INSUFFICIENT_USDT
    )
      return "you should have sufficient ETH and USDT to buy";

    if (claimState === ClaimState.CLAIMED)
      return "You have already claimed your NFT but can buy now with USDT";

    return "Maximum 3 NFTs per transaction!";
  }, [claimState, mintState]);

  const transactionCallback = useCallback(async () => {
    if (
      mintState === MintState.ELIGIBLE &&
      claimState !== ClaimState.ELIGIBLE &&
      approvalState !== ApprovalState.UNKNOWN &&
      approvalState === ApprovalState.NOT_APPROVED
    ) {
      setModalState((prev) => ({
        ...prev,
        pendingMessage: <>Approval for USDT</>,
      }));
      const response = await approveCallback();
      console.log("response >>> ", response);
    }

    if (claimState === ClaimState.ELIGIBLE) {
      const response = await claimCallback();
      console.log("response >>> ", response);
      return response;
    }

    if (mintState === MintState.ELIGIBLE) {
      const response = await mintCallback();
      console.log("response >>> ", response);
      return response;
    }

    return;
  }, [
    approvalState,
    setModalState,
    approveCallback,
    claimState,
    mintState,
    claimCallback,
    mintCallback,
  ]);

  const handleButtonClick = useCallback(async () => {
    setModalState({
      showConfirm: true,
      attemptingTxn: true,
      txHash: undefined,
      errorMessage: undefined,
    });

    try {
      const response = await transactionCallback();

      setModalState((prev) => ({
        ...prev,
        txHash: response.hash,
      }));

      const resWait = await response.wait();
      if (resWait?.status === 1) {
        setModalState((prev) => ({
          ...prev,
          attemptingTxn: false,
        }));
      } else {
        setModalState((prev) => ({
          ...prev,
          showConfirm: false,
        }));
      }
    } catch (error) {
      setModalState((prev) => ({
        ...prev,
        attemptingTxn: false,
        errorMessage: undefined,
      }));

      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        console.log("error in handleMint -> ", error);
      }
    }
  }, [transactionCallback]);

  const handleConfirmDismiss = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      showConfirm: false,
      attemptingTxn,
      txHash,
    }));
  }, [attemptingTxn, txHash]);

  const bgStyles = {
    backgroundImage: screens.sm ? `url(${mobImg})` : `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  };

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    console.log("triedEager not connected!");
    return null;
  }

  if (notActiveAndError) {
    console.log("not active and error!");
    return (
      <MintSectionStyled screens={screens}>
        <div style={bgStyles}>
          <div className="d-flex justify-content-around align-items-center flex-wrap">
            <div className="text-center text-white">
              Oops! An unknown error occurred. <br></br>Please refresh the page,
              or visit from another browser or device.
            </div>
          </div>
        </div>
      </MintSectionStyled>
    );
  }

  return (
    <MintSectionStyled screens={screens}>
      <div style={bgStyles}>
        <div className="text-center">
          {(claimState === ClaimState.NOT_ELIGIBLE ||
            claimState === ClaimState.CLAIMED ||
            mintState === MintState.ELIGIBLE) && (
            <>
              <div className="count-btn">
                <button
                  onClick={() =>
                    setNoOfTokens((prev) => (prev == 1 ? 1 : prev - 1))
                  }
                >
                  -
                </button>
                <div>{noOfTokens}</div>
                <button
                  onClick={() =>
                    setNoOfTokens((prev) => (prev == 3 ? 3 : prev + 1))
                  }
                >
                  +
                </button>
              </div>
              <div className="text">
                <InfoIcon /> {noOfTokens} NFT = {tokenPrice} USDT
              </div>

              <div className="mb-5"></div>
            </>
          )}
          <Button
            className="buy-btn"
            disabled={btnDisable}
            onClick={handleButtonClick}
          >
            {btnText}
          </Button>
          <div className="text">
            <InfoIcon /> {infoText}
          </div>
        </div>

        <div>
          <ConnectWalletBtn />
        </div>
      </div>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleConfirmDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={errorMessage}
      />
    </MintSectionStyled>
  );
};

MintSection.propTypes = {
  img: PropTypes.string,
  mobImg: PropTypes.string,
};

const MintSectionStyled = styled.div`
  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    margin-top: 12px;
    color: ${colors.white};
  }

  .count-btn {
    padding: 15px 60px;
    border-radius: 18px;
    background: linear-gradient(180deg, #e1eeff 0%, #c7d9ef 100%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 25px;

    > button {
      background: none;
      border: none;
      outline: inherit;
    }
  }

  .buy-btn {
    padding: ${(props) => (props.screens.sm ? "15px 20px" : "15px 60px")};
    font-family: Roboto;
    box-shadow: 2.07629px 4.15259px 2.76839px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: ${colors.white};
    background: linear-gradient(180deg, #2681ef 0%, #1059b2 100%);
  }
`;

export default MintSection;
