import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import store from "./state";
import getLibrary from "./utils/getLibrary";
import ApplicationUpdater from "./state/application/updater";
import MulticallUpdater from "./state/multicall/updater";
import Web3ManagerUpdater from "./state/web3Manager/updater";
import "bootstrap/dist/css/bootstrap.min.css";

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

// State Updaters
function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <MulticallUpdater />
      <Web3ManagerUpdater />
    </>
  );
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Updaters />
        <HashRouter>
          <App />
        </HashRouter>
      </Web3ReactProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
