import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import MiuraPage from "./pages/MiuraPage";
import AdminPage from "./pages/AdminPage";
import { Footer, Header, PreLoader } from "./components";
import { colors } from "./helpers/colors";
import styled from "styled-components";
import "./styles.css";

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      setLoading(false);
    };
  }, [setLoading]);

  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        <AppStyled>
          <Header />
          <Routes>
            <Route path="/" element={<MiuraPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </AppStyled>
      )}
    </>
  );
};

const AppStyled = styled.div`
  background-color: ${colors.bgColor};
`;

export default App;
