import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {
  FacebookIcon,
  headerPic1,
  headerPic2,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../helpers/images";
import styled from "styled-components";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";
import { colors } from "../helpers/colors";

const Header = () => {
  const screens = useWindowMediaQuery();
  const img1 = (
    <Navbar.Brand href="/">
      <img alt="Bertone-Design" src={headerPic1} width="127" height="127" />
    </Navbar.Brand>
  );
  const img2 = (
    <Navbar.Brand href="/">
      <img alt="Ncc-Crazy-Colors" src={headerPic2} width="97" height="68" />
    </Navbar.Brand>
  );
  if (screens.sm) {
    return (
      <Navbar>
        <Container className="d-flex justify-content-between align-items-center">
          {img1}
          {img2}
        </Container>
      </Navbar>
    );
  }
  return (
    <Navbar>
      <Container className="d-flex justify-content-between align-items-center">
        <div>
          {img1}
          {img2}
        </div>

        <IconsStyled>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/bertonedesign/"
          >
            <FacebookIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/bertone-design-italia/"
          >
            <LinkedinIcon />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/bertone_design"
          >
            <TwitterIcon />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/bertonedesignmilano/"
          >
            <InstagramIcon />
          </a>
        </IconsStyled>
      </Container>
    </Navbar>
  );
};

const IconsStyled = styled.div`
  > a > svg {
    margin-right: 30px;
    cursor: pointer;
    > path {
      fill: ${colors.textColor};
      color: ${colors.textColor};
    }
  }
`;

export default Header;
