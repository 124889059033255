import styled from "styled-components";
import PropTypes from "prop-types";
import useWindowMediaQuery from "../helpers/useMediaQueryHook";

const Section4 = ({ img }) => {
  const screens = useWindowMediaQuery();
  return (
    <Section4Styled screens={screens}>
      <div className="text-center mt-5 pt-5 mb-5 pb-5">
        <img src={img} className="bike" alt="bike4" />
      </div>
    </Section4Styled>
  );
};

Section4.propTypes = {
  img: PropTypes.string,
};

const Section4Styled = styled.div`
  .bike {
    max-width: ${(props) => (props.screens.sm ? "100%" : "60%")};
  }
`;

export default Section4;
